/* eslint-disable */
// Theses classes should correspond to the softmax output of your model.

export const MODEL_CLASSES = {
  0: 'Fresh Apple',
  1: 'Fresh Banana',
  2: 'Fresh Orange',
  3: 'Rotten Apple',
  4: 'Rotten Banana',
  5: 'Rotten Orange'
};
