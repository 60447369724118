import React, { Component } from 'react';
import './About.css';

/**
 * Class to handle the rendering of the Home page.
 * @extends React.Component
 */
export default class Home extends Component {
  render() {
    return (
      <div className="About container">
        <h1>About</h1>
        <br></br>
        <p>
          Amazing web application that use magic algorithm that classify fresh and rotten fruits (sadly, it can only classify Apple, Banana, and Orange).
          Turns out it using Image Classification with TensorFlow.js!
          This app use our converted TensorFlow.js format CNN model to provide better experience when using this app as PWA and provide offline capabilities.
          You can use this app to decide which fruits is fresh or rotten and decide which fruit is apple, banana, or orange
          (in case you hit your head or something, LOL)
          <br></br>
          <br></br>
          </p>
      </div>
    );
  }
}
